const FORMATS = [
  {
    formatName: 'Twitter profile picture size',
    formatDescription:
      "Twitter's profile picture size has a maximum of 400 x 400 pixel. The pfp pics size here is small so we recommend to use colors and shapes to make your pfp stand out. Create an appealing Twitter profile picture that people can relate to and will make them want to stay on your page.",
    formatSize: { width: 400, height: 400 },
    selected: false,
    formatColumn: 1,
    id: 'tw_1',
  },
  {
    formatName: 'Twitter conversation card',
    formatDescription:
      'Make sure Twitter conversation cards are at 800 x 418 pixels. They encourage interaction with your tweet and leverage your followers’ own followers. You can use them to unlock hidden content or simply to create excitement about a product.',
    formatSize: { width: 800, height: 418 },
    selected: false,
    formatColumn: 1,
    id: 'tw_2',
  },
  {
    formatName: 'Twitter fleets images',
    formatDescription:
      'Use whatever images work for your brand and make sure that you to stick to 1080 x 1920 pixels. Twitter fleets, or stories in a bubble, only stay for 24h so make sure they’re eye-catching.',
    formatSize: { width: 1080, height: 1920 },
    selected: false,
    formatColumn: 1,
    id: 'tw_3',
  },
  {
    formatName: 'Twitter video thumbnail',
    formatDescription:
      'The ideal Twitter video thumbnail size is 640 x 360 pixels although the best images are those that your target audience can relate to emotionally. Increase the click rate of your Twitter video by making your thumbnail interesting and enticing.',
    formatSize: { width: 640, height: 360 },
    selected: false,
    formatColumn: 1,
    id: 'tw_4',
  },
  {
    formatName: 'Twitter banner size',
    formatDescription:
      'The perfect size for your Twitter banners is 1500 x 500 pixels but leave some space at the top and bottom to cater for different devices. Tell your story and grab people’s attention with a visually powerful Twitter header and one-liner.',
    formatSize: { width: 1500, height: 500 },
    selected: false,
    formatColumn: 2,
    id: 'tw_5',
  },
  {
    formatName: 'Twitter website card ad',
    formatSize: { width: 800, height: 800 },
    formatDescription:
      'Simply make sure your ads stand out and mean something to people, all within 800 x 800 pixels. Call your target audience to your site with Twitter website cards. You can even include ads to get even higher engagement.',
    selected: false,
    formatColumn: 2,
    id: 'tw_6',
  },
  {
    formatName: 'Twitter card image (min)',
    formatDescription:
      'Minimum size is 120 x 120 pixels for both images and videos. Drive more traffic with Twitter cards and the right image to grab attention. Make sure it’s simple to view and that it makes people want to re-tweet.',
    formatSize: { width: 120, height: 120 },
    selected: false,
    formatColumn: 2,
    id: 'tw_7',
  },
  {
    formatName: 'Twitter direct message',
    formatDescription:
      'Twitter direct messages need to be within 800 x 418 pixels in size, although you can still use multimedia content. They can be a powerful way to remind people of your brand, as well as keeping your audience engaged and updated.',
    formatSize: { width: 800, height: 418 },
    selected: false,
    formatColumn: 2,
    id: 'tw_8',
  },
  {
    formatName: 'Twitter post image',
    formatDescription:
      'Promote your brand and stand out from the crowd with colorful Twitter post images with ideal size of 1200 x 675 pixels. You can even now also post 4 images in a collage if that’s going to boost your brand.',
    formatSize: { width: 1200, height: 675 },
    selected: false,
    formatColumn: 3,
    id: 'tw_9',
  },
  {
    formatName: 'Twitter app card ad',
    formatDescription:
      'Get more users to your app with Twitter App cards and the perfect ad all within 800 x 800 pixels. You job is to design the image or video that visually represents your style and unique product or service.',
    formatSize: { width: 800, height: 800 },
    selected: false,
    formatColumn: 3,
    id: 'tw_10',
  },
  {
    formatName: 'Twitter carousels',
    formatDescription:
      'Twitter carousels, which can be linked to your website, need to be 800 x 800 pixels and the rest is up to your creativity. Slideshows, or rotating banners, are a powerful way to showcase your product or services.',
    formatSize: { width: 800, height: 800 },
    selected: false,
    formatColumn: 3,
    id: 'tw_11',
  },
];

export default FORMATS;
