const FORMATS = [
  {
    formatName: 'Instagram Profile Pic Size',
    formatDescription:
      'Instagram pfp should be 320 x 320 pixels. Although the pfp for Instagram will be downloaded in a square format, Instagram profile pictures are circular. Be sure that any features you would like to concentrate are centered so they don’t become cropped out when using our insta dp.',
    formatSize: { width: 320, height: 320 },
    selected: false,
    formatColumn: 1,
    id: 'ig_1',
  },
  {
    formatName: 'Instagram ad square',
    formatDescription:
      'The Instagram ad square photos should have a 1:1 ratio, and optimized at 1080 x 1080 pixels. Using an incorrectly sized ad on Instagram can have a damaging impact on your campaign.',
    formatSize: { width: 1080, height: 1080 },
    selected: false,
    formatColumn: 1,
    id: 'ig_2',
  },
  {
    formatName: 'Instagram ad portrait',
    formatDescription:
      'The portrait provides value for your content. Stick to the 1080 pixel wide, and up to 1350 pixels tall. The aspect ratio 4:5 or 8:10 is available as a cropping method.',
    formatSize: { width: 1080, height: 1350 },
    selected: false,
    formatColumn: 1,
    id: 'ig_3',
  },
  {
    formatName: 'Instagram reels',
    formatDescription:
      'It is tricky to design and style a Reels cover. We opt for Instagram Reels that are 9:16 (1080 x 1920 pixels) or vertically full screen, that is the identical structure of Instagram Stories.',
    formatSize: { width: 1080, height: 1920 },
    selected: false,
    formatColumn: 1,
    id: 'ig_4',
  },
  {
    formatName: 'Instagram square size',
    formatDescription:
      'An Instagram photo size for Square format is 1080 x 1080 pixels in size. Anytime Instagram compresses the file, it maintains its high quality. While Instagram photo sizes also come in horizontal and vertical formats, square posts on the Instagram user profile grid will always show at a 1:1 ratio.',
    formatSize: { width: 1080, height: 1080 },
    selected: false,
    formatColumn: 2,
    id: 'ig_5',
  },
  {
    formatName: 'Instagram stories',
    formatDescription:
      'Instagram Stories, consist of a minimum aspect ratio of 1.91:1 and a max of 9:16. The most commenly used Instagram ratio is 9:16 with an Instagram image size of 1080 x 1920 pixels.',
    formatSize: { width: 1080, height: 1920 },
    selected: false,
    formatColumn: 2,
    id: 'ig_6',
  },
  {
    formatName: 'Instagram ad landscape',
    formatDescription:
      'The appropriate Instagram size for landscape image ad sizes needs to be 1080 x 566 pixels, and include an aspect ratio of 1.9:1. Select the ideal ad size prior to uploading images to optimize your campaign.',
    formatSize: { width: 1080, height: 566 },
    selected: false,
    formatColumn: 2,
    id: 'ig_7',
  },
  {
    formatName: 'Instagram IGTV cover',
    formatDescription:
      "Instagram suggests an aspect ratio of 1:1.55 and a specifications for 420 x 654 pixels. The cover shot appears on a channel's page and inside the IGTV lists, so it's worthwhile getting it the proper dimensions.",
    formatSize: { width: 420, height: 654 },
    selected: false,
    formatColumn: 2,
    id: 'ig_8',
  },
  {
    formatName: 'Instagram landscape post',
    formatDescription:
      "Instagram landscape size is 1080 x 566 pixels. It's minimum resolution: 600 x 315 pixels, with a recommended aspect ratio of 1.91:1. Typically chosen for nature and open-air photographs, the landscape option is an additional publishable size.",
    formatSize: { width: 1080, height: 566 },
    selected: false,
    formatColumn: 3,
    id: 'ig_9',
  },
  {
    formatName: 'Instagram portrait post',
    formatDescription:
      'The preferred resolution is 1080 x 1350 pixels. Its minimum resolution is 600 x 750 pixels, with a advised aspect ratio of 4:5. The portrait will show vertically and will occupy nearly all of the screen.',
    formatSize: { width: 1080, height: 1350 },
    selected: false,
    formatColumn: 3,
    id: 'ig_10',
  },
  {
    formatName: 'Instagram thumbnail',
    formatDescription:
      "Instagram thumbnails are scaled-down versions of the photos from the profile. Thumbnail size is 161 x 161 Pixels. Whether it's a video or an image, Instagram generates a thumbnail image in your user profile.",
    formatSize: { width: 161, height: 161 },
    selected: false,
    formatColumn: 3,
    id: 'ig_11',
  },
  {
    formatName: 'Instagram post size',
    formatDescription:
      'Instagram dimensions for your feed will vary depending on the Instagram post size ratio: Landscape: 1080 x 566 pixels Portrait: 1080 x 1350 pixels Square: 1080 x 1080 pixels Supported Instagram ratios: Anywhere between 1.91:1 and 4:5',
    formatSize: { width: 1080, height: 1350 },
    selected: false,
    formatColumn: 3,
    id: 'ig_12',
  },
];

export default FORMATS;
