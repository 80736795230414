const initDb = ({ dbName, dbVersion, objectStores }) =>
  new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, dbVersion);
    let db;

    request.onupgradeneeded = e => {
      const database = e.target.result;
      objectStores.map(objectStoreData => {
        database.createObjectStore(
          objectStoreData.name,
          objectStoreData.params,
        );
      });
    };

    request.onsuccess = () => {
      db = request.result;
      resolve(db);
    };

    request.onerror = e => {
      // Returns error event
      reject(e);
    };
  });

export const initPhotosDb = async () =>
  initDb({
    dbName: 'PhotoResizer',
    dbVersion: 1,
    objectStores: [
      {
        name: 'originalImage',
        params: {
          keyPath: 'name',
        },
      },
      {
        name: 'originalImageState',
        params: {
          keyPath: 'name',
        },
      },
      {
        name: 'thumbnails',
        params: {
          keyPath: 'name',
        },
      },
      {
        name: 'downloadQueue',
        params: {
          keyPath: 'name',
        },
      },
    ],
  });

export const getAllObjectsFromDatabaseStore = async ({ storeName }) => {
  const db = await initPhotosDb();
  if (db) {
    const transaction = db.transaction(storeName, 'readonly');
    const store = transaction.objectStore(storeName);
    const getTransaction = store.getAll();

    return new Promise((resolve, reject) => {
      getTransaction.onsuccess = e => {
        const el = e.target.result;
        resolve(el);
      };

      getTransaction.onerror = e => {
        reject(e);
      };
    });
  }
};

export const putObjectInDatabase = async ({
  data,
  name,
  storeName,
  timestamp,
}) => {
  const object = {
    name,
    data,
    timestamp,
  };

  const db = await initPhotosDb();
  if (db) {
    const transaction = db.transaction(storeName, 'readwrite');
    const store = transaction.objectStore(storeName);
    store.put(object);
  }
};

export const clearObjectStore = async ({ storeName }) => {
  const db = await initPhotosDb();

  if (db) {
    const transaction = db.transaction(storeName, 'readwrite');
    const store = transaction.objectStore(storeName);
    store.clear();
  }
};
