export const addHours = (date, numOfHours) => {
  date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);

  return date;
};

export const isDateInFuture = givenDate => {
  const now = new Date();
  const then = new Date(givenDate);
  return then > now;
};
