const FORMATS = [
  {
    formatName: 'Google Display skyscraper',
    formatDescription:
      'Google Display Skyscraper sidebar ads size is 120 x 600 pixels. Grab attention and build brand engagement through Skyscraper sidebar ads. Make an impression with the right images and animations and increase your conversion rates. ',
    formatSize: { width: 120, height: 600 },
    selected: false,
    formatColumn: 1,
    id: 'gd_1',
  },
  {
    formatName: 'Google Display leaderboard',
    formatDescription:
      'Customize your Google Display Leaderboard ad at 728 × 90 pixels Increase your traffic by building your unique links with several clickable images. These are in prime positions and can be optimized to grab attention. ',
    formatSize: { width: 728, height: 90 },
    selected: false,
    formatColumn: 1,
    id: 'gd_2',
  },
  {
    formatName: 'Google Display mobile banner',
    formatDescription:
      'With 300 x 50 pixels of space, make sure you use optimized Google Display mobile banners. Visuals and photos of faces make more of an impression than text on the small screen. Colors and animations also usually appeal to people. ',
    formatSize: { width: 300, height: 50 },
    selected: false,
    formatColumn: 1,
    id: 'gd_3',
  },
  {
    formatName: 'Google Display banner',
    formatDescription:
      'Make sure it’s within 468 x 68 pixels and doesn’t blend into the background. Promote your business with appealing Google Display banners. Use the best combination of images, text and animations for your brand’s style and image. ',
    formatSize: { width: 468, height: 60 },
    selected: false,
    formatColumn: 1,
    id: 'gd_4',
  },
  {
    formatName: 'Google Display portrait',
    formatDescription:
      'With the larger size, 300 x 1050 pixels, of Google Display Portrait ads you can easily create a compelling story and call to action. Make it visually attracted and add pictures of people to connect emotionally to your audience. ',
    formatSize: { width: 300, height: 1050 },
    selected: false,
    formatColumn: 1,
    id: 'gd_5',
  },
  {
    formatName: 'Google Display panorama',
    formatDescription:
      'Google Display Panorama ads are 980 x 120 pixels and allow for good spacing of images, animations and text. Make sure your know what’s going to appeal to your target audience and make it work with our customizable templates. ',
    formatSize: { width: 980, height: 120 },
    selected: false,
    formatColumn: 1,
    id: 'gd_6',
  },
  {
    formatName: 'Google Display large mobile banner',
    formatDescription:
      'Google Display large mobile banner ads are 320 × 100 pixels in size and work well for large phone screens that are increasingly popular. These banners can be hugely attention grabbing if designed to match your target audience’s needs and likes. ',
    formatSize: { width: 320, height: 100 },
    selected: false,
    formatColumn: 1,
    id: 'gd_22',
  },
  {
    formatName: 'Google Display billboard',
    formatDescription:
      'As the largest ad measured at 970 × 250 pixels, Google Display Billboard ads stand out especially when placed above content. Ads can easily get lost but a billboard allows you to space things out and perfectly balance text with images. ',
    formatSize: { width: 970, height: 250 },
    selected: false,
    formatColumn: 1,
    id: 'gd_7',
  },
  {
    formatName: 'Google Display vertical rectangle',
    formatDescription:
      'The Google Display Vertical rectangle, or 240 × 400 pixels, is popular in sidebars and alongside some key content story. Don’t forget to design the right color and image combination that your target audience can relate to. ',
    formatSize: { width: 240, height: 400 },
    selected: false,
    formatColumn: 2,
    id: 'gd_8',
  },
  {
    formatName: 'Google Display half banner',
    formatDescription:
      'A Google Display half banner at 234 x 60 pixels can fit into smaller spaces but this means that it will also have to be that much more eye-catching. Get the right colors to stand out more.',
    formatSize: { width: 234, height: 60 },
    selected: false,
    formatColumn: 2,
    id: 'gd_9',
  },
  {
    formatName: 'Google Display small square',
    formatDescription:
      'The Google Display small square ad option at 200 × 200 pixels is both responsive and versatile. It can adjust to most sizes and help you reach even more potential users. ',
    formatSize: { width: 200, height: 200 },
    selected: false,
    formatColumn: 2,
    id: 'gd_10',
  },
  {
    formatName: 'Google Display wide skyscraper',
    formatDescription:
      'With a 160 x 600 wide skyscraper Google Display ad you can make even more impact. Get the most exposure for your audience with the right visuals for your industry and more than one clickable image. ',
    formatSize: { width: 160, height: 600 },
    selected: false,
    formatColumn: 2,
    id: 'gd_11',
  },
  {
    formatName: 'Google Display large leaderboard',
    formatDescription:
      'Google Display large leaderboard ads size is 970 x 90 pixels. Large leaderboard ads are a powerful way to grab attention and encourage clicks. Design the right layout of links and clickable images to optimize for traffic. ',
    formatSize: { width: 970, height: 90 },
    selected: false,
    formatColumn: 2,
    id: 'gd_12',
  },
  {
    formatName: 'Google Display large mobile banner',
    formatDescription:
      'Google Display large mobile banner ads are 320 × 100 pixels in size and work well for large phone screens that are increasingly popular. These banners can be hugely attention grabbing if designed to match your target audience’s needs and likes. ',
    formatSize: { width: 320, height: 50 },
    selected: false,
    formatColumn: 2,
    id: 'gd_13',
  },
  {
    formatName: 'Google Display top banner',
    formatDescription:
      'Make your Google Display top banner ad at 930 x 180 pixels to promote your business. Make it clear and impactful by using the right text and images that your target audience can relate to. ',
    formatSize: { width: 930, height: 180 },
    selected: false,
    formatColumn: 2,
    id: 'gd_14',
  },
  {
    formatName: 'Google Display square',
    formatDescription:
      'Google Display square, sized at 250 x 250 pixels, is small and easily adaptable into small spaces. Simply arrange any images and text to be as visible as possible such that your message stands out clearly. ',
    formatSize: { width: 250, height: 250 },
    selected: false,
    formatColumn: 3,
    id: 'gd_15',
  },
  {
    formatName: 'Google Display medium rectangle',
    formatDescription:
      'Medium rectangle ads at 300 x 250 pixels look good on Google Display either embedded within text or at the end of articles. Of course, you’ll have to choose the right template and format to make your brand stand out. ',
    formatSize: { width: 300, height: 250 },
    selected: false,
    formatColumn: 3,
    id: 'gd_16',
  },
  {
    formatName: 'Google Display netboard',
    formatDescription:
      'With 580 x 400 pixels to play around with, you can create responsive Google Display netboard ads with any multimedia option. Create your unique blend of customized images, text and animations to boost brand awareness.',
    formatSize: { width: 580, height: 400 },
    selected: false,
    formatColumn: 3,
    id: 'gd_17',
  },
  {
    formatName: 'Google Display half-page ad',
    formatDescription:
      'You can create some amazing ads with the Google Display half-page option sized at 300 x 600 pixels. They are versatile and can be as animated as much or as little as you want. ',
    formatSize: { width: 300, height: 600 },
    selected: false,
    formatColumn: 3,
    id: 'gd_18',
  },
  {
    formatName: 'Google Display triple widescreen',
    formatDescription:
      'The Google Display triple widescreen is 250 x 360 pixels in size and works well next to webpage sidebars. This size seems to work particularly well in European countries, for example, Sweden. ',
    formatSize: { width: 250, height: 360 },
    selected: false,
    formatColumn: 3,
    id: 'gd_19',
  },
  {
    formatName: 'Google Display inline rectangle',
    formatDescription:
      'The Google Display inline rectangle ads size are 300 x 250 pixels, which is great for placing within text or at the end of articles. Use our multimedia platform to make sure they stand out with the right colors for your brand.',
    formatSize: { width: 300, height: 250 },
    selected: false,
    formatColumn: 3,
    id: 'gd_20',
  },
  {
    formatName: 'Google Display large rectangle',
    formatDescription:
      'Google Display large rectangle ads at 336 x 280 pixels in size are popular because of the extra space they provide. You’ll get increased reactions from your audience by integrating visuals with punchy text. ',
    formatSize: { width: 336, height: 280 },
    selected: false,
    formatColumn: 3,
    id: 'gd_21',
  },
];

export default FORMATS;
