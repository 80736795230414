const FORMATS = [
  {
    formatName: 'YouTube Channel Art TV',
    formatDescription:
      'Simply ensure it’s optimized at 2560 x 1440 pixels and works across all devices. Boost your YouTube channel with an eye-catching banner at the top of your page that instantly communicates your brand and style.',
    formatSize: { width: 2560, height: 1440 },
    formatMaxHeight: 146,
    formatColumn: 1,
    selected: false,
    id: 1,
  },
  {
    formatName: 'YouTube Channel Art desktop banner',
    formatSize: { width: 2560, height: 423 },
    formatDescription:
      'Ideal size is 2560 x 1440 pixels or 1855 x 423 pixels if you want to stay in the safe zone for tablets. Enhance your audience experience by uploading your very own customized channel banner.',
    formatMaxHeight: 42,
    selected: false,
    formatColumn: 1,
    id: 2,
  },
  {
    formatName: 'YouTube Channel Art tablet banner',
    formatSize: { width: 1855, height: 423 },
    formatDescription:
      'Ideal size is 2560 x 1440 pixels or 1855 x 423 pixels if you want to stay in the safe zone for tablets. Enhance your audience experience by uploading your very own customized channel banner.',
    formatMaxHeight: 60,
    formatColumn: 2,
    selected: false,
    id: 3,
  },
  {
    formatName: 'YouTube Channel Art mobile banner',
    formatSize: { width: 1546, height: 423 },
    formatDescription:
      'Make sure you stay in your safe zone of 1546 x 423 pixels, even if some of your artwork lies outside side for wider screens. Personalize your YouTube channel with an awesome channel banner to pull in your audience.',
    formatMaxHeight: 72,
    selected: false,
    formatColumn: 3,
    id: 4,
  },
];

export default FORMATS;
