const FORMATS = [
  {
    formatName: 'Facebook Ads image ad',
    formatDescription:
      'The optimum Facebook image ad size is 1200 x 628 pixels with visuals rather than text. Motivate and engage your audience with powerful ads showing happy faces, fun colors, loveable pets or even something funny.',
    formatSize: { width: 1200, height: 628 },
    selected: false,
    formatColumn: 1,
    id: 'fba_1',
  },
  {
    formatName: 'Facebook Ads story ad',
    formatDescription:
      'Facebook Stories occupy the entire phone screen so the most suitable proportions will be 1,080 x 1,920 pixels with an aspect ratio of 9:16.',
    formatSize: { width: 1080, height: 1920 },
    selected: false,
    formatColumn: 1,
    id: 'fba_2',
  },
  {
    formatName: 'Facebook Ads audience network',
    formatDescription:
      'Facebook Audience Network ad advisable graphic size is 1200 x 628 pixels. The specifications for minimum width and height is 600 pixels. Facebook Audience Network lets you have your ads suggested on tons of high-quality sites and apps.',
    formatSize: { width: 1200, height: 628 },
    selected: false,
    formatColumn: 2,
    id: 'fba_3',
  },
  {
    formatName: 'Facebook Ads carousel image',
    formatDescription:
      'For Home Carousel Ads, the preferred image size is 1080 x 1080 pixels, max image size of 30MG, and aspect ratio: 1:1. Carousel ad Image guidelines include a bare minimum dimensional width and height of 600 pixels.',
    formatSize: { width: 1200, height: 1200 },
    selected: false,
    formatColumn: 2,
    id: 'fba_4',
  },
  {
    formatName: 'Facebook Ads messenger image ad',
    formatDescription:
      'Photo dimensions are 1200 x 628 pixels. While the minimum width is 500 pixels. Facebook Messenger Story Image ads should leave 250 pixels at the top and bottom of the image to get the best Facebook Image size.',
    formatSize: { width: 1200, height: 628 },
    selected: false,
    formatColumn: 3,
    id: 'fba_5',
  },
  {
    formatName: 'Facebook Ads audience network link',
    formatDescription:
      "Audience Network Ad Image proportions are a minimum 398 x 208 pixels. It's also a good idea to work with the image size of 1200 x 628 pixels. With a link, the image aspect ratio is 1.9:1.",
    formatSize: { width: 398, height: 208 },
    selected: false,
    formatColumn: 3,
    id: 'fba_6',
  },
];

export default FORMATS;
