import React from 'react';

import FORMATS_YT_CHANNEL_ART from 'constants/texts/formats/yt';
import FORMATS_FACEBOOK from 'constants/texts/formats/fb';
import FORMATS_SNAPCHAT from 'constants/texts/formats/snapchat';
import FORMATS_INSTAGRAM from 'constants/texts/formats/instagram';
import FORMATS_YT_BANNER from 'constants/texts/formats/yt-banner';
import FORMATS_WEBSITE from 'constants/texts/formats/website';
import FORMATS_LINKEDIN from 'constants/texts/formats/linkedin';
import FORMATS_FACEBOOK_ADS from 'constants/texts/formats/fb-ads';
import FORMATS_EMAIL from 'constants/texts/formats/email';
import FORMATS_TIKTOK from 'constants/texts/formats/tiktok';
import FORMATS_TWITTER from 'constants/texts/formats/twitter';
import FORMATS_PINTEREST from 'constants/texts/formats/pinterest';
import FORMATS_GOOGLE from 'constants/texts/formats/google';

import {
  IconFacebook,
  IconSnapchat,
  IconYoutube,
  IconInstagram,
  IconWebsite,
  IconLinkedin,
  IconFacebookAd,
  IconEmail,
  IconTiktok,
  IconTwitter,
  IconPinterest,
  IconGoogle,
} from 'constants/texts/icons';

export const PLATFORM_YT_CHANNEL_ART = 'YouTube_Channel_Art';
export const PLATFORM_FACEBOOK_ADS = 'Facebook_Ads';
export const PLATFORM_INSTAGRAM = 'Instagram';
export const PLATFORM_FACEBOOK = 'Facebook';
export const PLATFORM_YT = 'YouTube';
export const PLATFORM_WEBSITE = 'Website';
export const PLATFORM_LINKEDIN = 'LinkedIn';
export const PLATFORM_EMAIL = 'Email';
export const PLATFORM_TIKTOK = 'TikTok';
export const PLATFORM_TWITTER = 'Twitter';
export const PLATFORM_PINTEREST = 'Pinterest';
export const PLATFORM_SNAPCHAT = 'Snapchat';
export const PLATFORM_GOOGLE_DISPLAY = 'Google_Display_Network';

export const PLATFORMS = [
  PLATFORM_FACEBOOK,
  PLATFORM_INSTAGRAM,
  PLATFORM_YT,
  PLATFORM_WEBSITE,
  PLATFORM_YT_CHANNEL_ART,
  PLATFORM_LINKEDIN,
  PLATFORM_FACEBOOK_ADS,
  PLATFORM_EMAIL,
  PLATFORM_TIKTOK,
  PLATFORM_TWITTER,
  PLATFORM_PINTEREST,
  PLATFORM_SNAPCHAT,
  PLATFORM_GOOGLE_DISPLAY,
];

export const NAMES = {
  [PLATFORM_YT_CHANNEL_ART]: 'YouTube Channel Art',
  [PLATFORM_FACEBOOK]: 'Facebook',
  [PLATFORM_FACEBOOK_ADS]: 'Facebook Ads',
  [PLATFORM_INSTAGRAM]: 'Instagram',
  [PLATFORM_YT]: 'YouTube Banner',
  [PLATFORM_WEBSITE]: 'Website',
  [PLATFORM_LINKEDIN]: 'LinkedIn',
  [PLATFORM_EMAIL]: 'Email',
  [PLATFORM_TIKTOK]: 'TikTok',
  [PLATFORM_TWITTER]: 'Twitter',
  [PLATFORM_PINTEREST]: 'Pinterest',
  [PLATFORM_SNAPCHAT]: 'Snapchat',
  [PLATFORM_GOOGLE_DISPLAY]: 'Google Banner Ads',
};

export const TITLES = PLATFORMS.reduce((titles, platform) => {
  titles[platform] = `Resize Image for ${NAMES[platform]}`;
  return titles;
}, {});

export const DESCRIPTIONS = {
  [PLATFORM_YT_CHANNEL_ART]:
    'Wow your subscribers with creative <strong>YT channel art</strong>...that fits.',
  [PLATFORM_FACEBOOK]:
    "We've taken all <strong>12 Facebook image sizes</strong> and made them easily accessible. Use our image resizer to resize images from <strong>stories</strong> to <strong>ads</strong>, <strong>posts</strong>, <strong>cover photos</strong> and <strong>event pages</strong>.",
  [PLATFORM_FACEBOOK_ADS]:
    'Businesses love our <strong>free photo resizer</strong> because we make it, well, easy! Use our image resizer for any and all <strong>Facebook Ad</strong> dimensions in one simple click!',
  [PLATFORM_INSTAGRAM]:
    'Our image resizer is the perfect tool for businesses and IG users, making it <strong>easier to resize</strong> images into stories, vertical horizontal and square posts, video thumbnails and more with no effort at all.',
  [PLATFORM_YT]:
    "Find the best fit for your <strong>YouTube Banner</strong> Image. Don't let your channel graphics go unnoticed.",
  [PLATFORM_WEBSITE]:
    'There is nothing worse than large images that slow down your website and hurt your SEO. Give your site performance a boost by optimizing your image sizes.',
  [PLATFORM_LINKEDIN]:
    'This network of professionals is a great place for any business to show off their content. Resize your photos for LinkedIn and get your profile noticed with high quality images.',
  [PLATFORM_EMAIL]:
    'The perfect email header can help you get your message across before the user even reads the first sentence. Get their attention and make sure it’s sized right with our <strong>free image resizer</strong>.',
  [PLATFORM_TIKTOK]:
    '<strong>TikTok video</strong> dimensions are super precise at 1080x1920 and the max file size is 287.6 MB - size it right with our easy steps above.',
  [PLATFORM_TWITTER]:
    "Whether you're creating a header or profile image - the perfect image size is a must to get the best quality visual. Our image resizer has you covered for all 11 Twitter dimensions in one easy click.",
  [PLATFORM_PINTEREST]:
    "<strong>Pins, thumbnails, board covers</strong>...use <a target='_blank' href='https://promo.com?asset=image-resizer'>promo.com</a>'s image resizer to create an aesthetically pleasing board with all your favorite pins.",
  [PLATFORM_SNAPCHAT]:
    "Snapchat images have one universal dimension -  1080 x 1920. Use our <a target='_blank' href='https://promo.com/tools/collage-maker?asset=image-resizer'>free online collage maker</a> to make your image more interesting before resizing it with our image resizer.",
  [PLATFORM_GOOGLE_DISPLAY]:
    'When creating ads for your business, ease is the best route. All 22 dimensions for <strong>Google Display Network</strong> banners can be created here, using Promo.com’s pic resizer.',
};

export const PLATFORM_ICONS = {
  [PLATFORM_SNAPCHAT]: IconSnapchat,
  [PLATFORM_YT_CHANNEL_ART]: IconYoutube,
  [PLATFORM_FACEBOOK]: IconFacebook,
  [PLATFORM_INSTAGRAM]: IconInstagram,
  [PLATFORM_YT]: IconYoutube,
  [PLATFORM_WEBSITE]: IconWebsite,
  [PLATFORM_LINKEDIN]: IconLinkedin,
  [PLATFORM_FACEBOOK_ADS]: IconFacebookAd,
  [PLATFORM_EMAIL]: IconEmail,
  [PLATFORM_TIKTOK]: IconTiktok,
  [PLATFORM_TWITTER]: IconTwitter,
  [PLATFORM_PINTEREST]: IconPinterest,
  [PLATFORM_GOOGLE_DISPLAY]: IconGoogle,
};

const addOrder = (format, index) => {
  format.order = index;
  return format;
};

export const FORMATS = {
  [PLATFORM_FACEBOOK]: FORMATS_FACEBOOK.map(addOrder),
  [PLATFORM_INSTAGRAM]: FORMATS_INSTAGRAM.map(addOrder),
  [PLATFORM_YT]: FORMATS_YT_BANNER.map(addOrder),
  [PLATFORM_WEBSITE]: FORMATS_WEBSITE.map(addOrder),
  [PLATFORM_LINKEDIN]: FORMATS_LINKEDIN.map(addOrder),
  [PLATFORM_YT_CHANNEL_ART]: FORMATS_YT_CHANNEL_ART.map(addOrder),
  [PLATFORM_FACEBOOK_ADS]: FORMATS_FACEBOOK_ADS.map(addOrder),
  [PLATFORM_EMAIL]: FORMATS_EMAIL.map(addOrder),
  [PLATFORM_TIKTOK]: FORMATS_TIKTOK.map(addOrder),
  [PLATFORM_TWITTER]: FORMATS_TWITTER.map(addOrder),
  [PLATFORM_PINTEREST]: FORMATS_PINTEREST.map(addOrder),
  [PLATFORM_SNAPCHAT]: FORMATS_SNAPCHAT.map(addOrder),
  [PLATFORM_GOOGLE_DISPLAY]: FORMATS_GOOGLE.map(addOrder),
};

export const PLACEHOLDERS = {
  [PLATFORM_YT_CHANNEL_ART]: [false, true, true],
  [PLATFORM_SNAPCHAT]: [false, true, true],
  [PLATFORM_FACEBOOK]: [false, true, true],
  [PLATFORM_INSTAGRAM]: [false, false, true],
  [PLATFORM_YT]: [false, true, true],
  [PLATFORM_WEBSITE]: [false, true, true],
  [PLATFORM_LINKEDIN]: [false, true, true],
  [PLATFORM_FACEBOOK_ADS]: [false, true, true],
  [PLATFORM_EMAIL]: [false, true, true],
  [PLATFORM_TIKTOK]: [false, true, true],
  [PLATFORM_TWITTER]: [false, true, true],
  [PLATFORM_PINTEREST]: [false, true, true],
  [PLATFORM_GOOGLE_DISPLAY]: [false, true, true],
};
