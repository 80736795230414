const FORMATS = [
  {
    formatName: 'Website portrait content',
    formatDescription:
      "The sizing for portrait content is specified as 1000 x 1500 pixels. Within your site webpages, there'll be sections containing images, textual content, call to action buttons, and so on. Essentially, these include the sections where pics are a component within the content.",
    formatSize: { width: 1000, height: 1500 },
    selected: false,
    formatColumn: 1,
    id: 'w_1',
  },
  {
    formatName: 'Website full width',
    formatDescription:
      'To ensure that your full width images look good across any device big or small the recommended size is 2400 x 1600 pixels. Note that devices have different ratio than the one you shoot and it is possible your images will be cropped when viewed on web',
    formatSize: { width: 2400, height: 1600 },
    selected: false,
    formatColumn: 2,
    id: 'w_2',
  },
  {
    formatName: 'Website landscape content',
    formatDescription:
      'The pixel dimension of your images need to be at least 1024 pixels wide. Suggested landscape is 1500 x 1000 pixels.Supported aspect ratios: Anywhere between 1.91:1 and 4:5.',
    formatSize: { width: 1500, height: 1000 },
    selected: false,
    formatColumn: 3,
    id: 'w_3',
  },
];

export default FORMATS;
