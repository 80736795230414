const FORMATS = [
  {
    formatName: 'TikTok in-feed ad image',
    formatDescription:
      "Opt for 1080 x 1080 pixels for optimal results. The best TikTok Ads practices will create content that fits the format of the platform. However, they'll also use high-quality images and videos that meet TikTok ad specs.",
    formatSize: { width: 1080, height: 1080 },
    selected: false,
    formatColumn: 1,
    id: 'tt_1',
  },
  {
    formatName: 'TikTok square video ad',
    formatDescription:
      'The best TikTok square video ad size is making video ads viewable, as well as engaging. The recommended video dimensions for square are 1080 x 1080 pixels. The minimum dimensions are 600 x 600 pixels.',
    formatSize: { width: 1080, height: 1080 },
    selected: false,
    formatColumn: 1,
    id: 'tt_2',
  },
  {
    formatName: 'TikTok pfp',
    formatDescription:
      "To make sure your pfp for TikTok looks it's best, use our suggested dimensions of 200 x 200 pixels. With out TikTok pfp downloader, you get that perfect sized pic that gets follows!",
    formatSize: { width: 200, height: 200 },
    selected: false,
    formatColumn: 2,
    id: 'tt_3',
  },
  {
    formatName: 'TikTok horizontal video ad',
    formatDescription:
      "It's important to realize that vertical videos work best on TikTok, but horizontal ones are also acceptable. The horizontal video ad dimensions are 1920 x 1080 pixels to ensure the best landscape quality.",
    formatSize: { width: 1920, height: 1080 },
    selected: false,
    formatColumn: 2,
    id: 'tt_4',
  },
  {
    formatName: 'TikTok vertical video ad',
    formatDescription:
      'To get the best vertical ads, it is advised for your upload to be no more than 500 MB. TikTok video sizes are 1080 × 1920 pixels. TikTok is typically viewed on a smartphone, so vertical video is ideal.',
    formatSize: { width: 1080, height: 1920 },
    selected: false,
    formatColumn: 3,
    id: 'tt_5',
  },
];

export default FORMATS;
