const FORMATS = [
  {
    formatName: 'Snapchat image share',
    formatDescription:
      'Adhere to 1080 x 1920 pixels. Give your younger target audience greater incentives to come to your brand with Snapchat image sharing for coupons and offers. Include emojis or fun filters and add short text since you can’t use captions.',
    formatSize: { width: 1080, height: 1920 },
    formatMaxHeight: 462,
    selected: false,
    formatColumn: 1,
    id: 'sn_1',
  },
];

export default FORMATS;
