const FORMATS = [
  {
    formatName: 'Facebook profile picture',
    formatDescription:
      'Facebook recommends a minimum size of 180 x 180 pixels for its Facebook profile picture size. Keen in mind that your pfp pics will display at 820 x 312 on desktops and 640 x 360 pixels on mobiles. Tip: When using a profile picture maker, use happy and colorful images to grab your audiences attention.',
    formatSize: { width: 180, height: 180 },
    selected: false,
    formatColumn: 1,
    id: 'fb_1',
  },
  {
    formatName: 'Facebook cover photo',
    formatDescription:
      'Facebook cover photo size varies between mobile and desktop. The ideal cover photo mobile size is 640 x 360 and the ideal cover photo desktop size for Facebook: 820 x 312 pixels. Get your Facebook page the attention it deserves with an optimized, sharp and appealing facebook cover photo.',
    formatSize: { width: 820, height: 312 },
    selected: false,
    formatColumn: 1,
    id: 'fb_2',
  },
  {
    formatName: 'Facebook marketplace',
    formatDescription:
      'Make your ads work for you at the optimum size of 1200 x 1200 pixels. Promote your marketplace with powerful ads that tell people instantly about your product or service and how it can help them.',
    formatSize: { width: 1200, height: 1200 },
    selected: false,
    formatColumn: 1,
    id: 'fb_3',
  },
  {
    formatName: 'Facebook stories',
    formatDescription:
      'Simply follow the optimal size of 1080 x 1920 pixels. Build engagement and generate more followers by reaching out to more people with your 24h Facebook stories. They are more effective at getting onto your potential audience’s feed.',
    formatSize: { width: 1080, height: 1920 },
    selected: false,
    formatColumn: 1,
    id: 'fb_4',
  },
  {
    formatName: 'Facebook link image',
    formatDescription:
      'Maximum image size is 8MB or between 600 x 315 and 1200 x 630 pixels. Although, the larger the better for optimum impact. Increase your click-throughs with simple, colorful link images or thumbnails by visually connecting with your audience.',
    formatSize: { width: 1200, height: 630 },
    selected: false,
    formatColumn: 2,
    id: 'fb_5',
  },
  {
    formatName: 'Facebook event image',
    formatDescription:
      'The ideal size is 1920 x 1005 pixels and make it stand out with bright colors to stand out on the white and blue Facebook background. Simple and impactful images can boost your event so your reach even more people.',
    formatSize: { width: 1920, height: 1005 },
    selected: false,
    formatColumn: 2,
    id: 'fb_6',
  },
  {
    formatName: 'Facebook instant articles',
    formatDescription:
      'Make sure you follow the ideal 1200 x 1200 pixel size. Tell your news with Facebook’s Instant Articles that loads your stories 4 times faster than normal mobile web. You can even place ads if you wish.',
    formatSize: { width: 1200, height: 1200 },
    selected: false,
    formatColumn: 2,
    id: 'fb_7',
  },
  {
    formatName: 'Facebook right column',
    formatDescription:
      'Keep to the 1200 x 1200 pixel size. Facebook right column ads for desktop can increase your traffic by building engagement and awareness. You have a larger creative space to work with that increase your views by 29%.',
    formatSize: { width: 1200, height: 1200 },
    selected: false,
    formatColumn: 2,
    id: 'fb_8',
  },
  {
    formatName: 'Facebook image post',
    formatDescription:
      'Simply stay within the size guidelines of between 600 x 315 and 1200 x 630 pixels whilst checking your upload rates. Create impact on your Facebook page with colorful post images and get more likes and shares.',
    formatSize: { width: 1200, height: 630 },
    selected: false,
    formatColumn: 3,
    id: 'fb_9',
  },
  {
    formatName: 'Facebook group cover',
    formatDescription:
      'Facebook recommends 1640 x 856 pixels. However, watch out for the grey areas as you might want to stay within 1640 x 664. Grab attention instantly with the perfect group cover photo to portray your style and image!',
    formatSize: { width: 1640, height: 856 },
    selected: false,
    formatColumn: 3,
    id: 'fb_10',
  },
  {
    formatName: 'Facebook highlighted image',
    formatDescription:
      'Make your announcements stand out with recommended 1200 x 717 size and high-quality resolution. Share your amazing milestones or events with highlighted images that call your audience to like and share.',
    formatSize: { width: 1200, height: 717 },
    selected: false,
    formatColumn: 3,
    id: 'fb_11',
  },
  {
    formatName: 'Facebook feed',
    formatDescription:
      'Make sure it fits to 1080 x 1350 pixels. People love stories, and building your content and adding stories to your Facebook feed creates emotional attachment that can boost your brand by 21%.',
    formatSize: { width: 1080, height: 1350 },
    selected: false,
    formatColumn: 3,
    id: 'fb_12',
  },
];

export default FORMATS;
