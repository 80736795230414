import createStore from 'react-waterfall';
import { isMobile } from 'react-device-detect';
import { FORMATS } from 'constants/texts/texts';
import sortSocialArray from 'utils/sortSocialArray';
import generateToken from 'utils/generateToken';
import {
  putObjectInDatabase,
  clearObjectStore,
  getAllObjectsFromDatabaseStore,
} from 'services/image-state-service';
import { addHours } from 'utils/dates';

const INITIAL_STATE = {
  originalImage: {
    source: null,
    width: null,
    height: null,
    xScale: null,
    yScale: null,
  },
  progress: {
    total: Object.keys(FORMATS).reduce(
      (total, socialName) => FORMATS[socialName].length + total,
      0,
    ),
    loaded: 0,
  },
  popupParams: {
    isOpen: false,
    onClose: () => null,
    type: '',
    title: '',
    description: [],
    payload: {},
    buttons: {},
  },
  thanksPopUpShowed: false,
  mainCroppedImage: null,
  originalImagePosition: { x: 0.8, y: 0.8 },
  thumbnails: FORMATS,
  pagePlatform: isMobile ? 'mobile' : 'desktop',
  originalImageDimensions: { originalWidth: 0, originalHeight: 0 },
  token: generateToken(),
  isLoadingFromDb: false,
};

const config = {
  initialState: { ...INITIAL_STATE },
  actionsCreators: {
    setImageToStore: async (state, actions, image) => {
      const originalImageObject = {
        width: image.width,
        height: image.height,
        source: image.source,
        xScale: '100%',
        yScale: '100%',
        timestamp: addHours(new Date(), 1),
      };

      if (
        originalImageObject.width &&
        originalImageObject.height &&
        originalImageObject.source
      ) {
        await putObjectInDatabase({
          data: originalImageObject,
          name: 'originalImage',
          storeName: 'originalImage',
        });
      }

      return {
        originalImage: originalImageObject,
        mainCroppedImage: image.source,
        originalImageDimensions: {
          originalWidth: parseFloat(image.width),
          originalHeight: parseFloat(image.height),
        },
      };
    },
    setOriginalImagePosition: (state, actions, originalImagePosition) => ({
      originalImagePosition,
    }),
    setAllThumbnailsState: (state, actions, thumbnails) => ({
      ...state,
      thumbnails,
    }),
    setImageToThumbnails: async (state, actions, cfg) => {
      const { thumbnails } = state;
      let social = thumbnails[cfg.social];
      let image = social.find(soc => soc.id === cfg.id);
      image = { ...image, source: cfg.source };
      social = social.filter(soc => soc.id !== cfg.id);

      const obj = { thumbnails: {} };

      Object.keys(thumbnails).forEach(socialName => {
        if (socialName === cfg.social) {
          obj.thumbnails[socialName] = [...social, image];
          obj.thumbnails[socialName] = obj.thumbnails[socialName].sort(
            sortSocialArray,
          );
        } else {
          obj.thumbnails[socialName] = thumbnails[socialName];
        }
      });

      const exists =
        (await getAllObjectsFromDatabaseStore({
          storeName: 'thumbnails',
        }).length) > 0;

      if (exists && !cfg.indexedDb) {
        await putObjectInDatabase({
          data: obj,
          name: 'thumbnails',
          storeName: 'thumbnails',
        });
      }

      return obj;
    },
    setSelected: async (state, actions, cfg) => {
      const { thumbnails } = state;
      let social = thumbnails[cfg.socialName];
      let image = social.find(soc => soc.id === cfg.imageId);
      image = { ...image, selected: !image.selected };
      social = social.filter(soc => soc.id !== cfg.imageId);

      const obj = { thumbnails: {} };

      Object.keys(thumbnails).forEach(socialName => {
        if (socialName === cfg.socialName) {
          obj.thumbnails[socialName] = [...social, image];
        } else {
          obj.thumbnails[socialName] = thumbnails[socialName];
        }
      });

      obj.thumbnails[cfg.socialName] = obj.thumbnails[cfg.socialName].sort(
        sortSocialArray,
      );

      await putObjectInDatabase({
        data: obj,
        name: 'thumbnails',
        storeName: 'thumbnails',
      });

      return obj;
    },
    setAllSelected: async (state, actions, cfg) => {
      const { thumbnails } = state;
      let social = thumbnails[cfg.socialName];
      social = social.map(soc => ({ ...soc, selected: cfg.isAllSelected }));

      const obj = { thumbnails: {} };

      Object.keys(thumbnails).forEach(socialName => {
        if (socialName === cfg.socialName) {
          obj.thumbnails[socialName] = social;
        } else {
          obj.thumbnails[socialName] = thumbnails[socialName];
        }
      });

      await putObjectInDatabase({
        data: obj,
        name: 'thumbnails',
        storeName: 'thumbnails',
      });
      return obj;
    },
    countProgress: state => ({
      progress: {
        ...state.progress,
        loaded: state.progress.loaded + 1,
      },
    }),
    resetState: async state => {
      await clearObjectStore({
        storeName: 'originalImage',
      });
      await clearObjectStore({
        storeName: 'originalImageState',
      });
      await clearObjectStore({
        storeName: 'thumbnails',
      });
      await clearObjectStore({
        storeName: 'downloadQueue',
      });
      return {
        ...INITIAL_STATE,
        thanksPopUpShowed: state.thanksPopUpShowed,
      };
    },
    openPopup: (state, actions, cfg) => {
      const {
        isOpen,
        type,
        onSubmit,
        title,
        description,
        buttons,
        payload,
      } = cfg;
      return {
        popupParams: {
          isOpen,
          onClose: actions.closePopup,
          onSubmit: onSubmit || actions.closePopup,
          type,
          title,
          description,
          payload,
          buttons,
        },
      };
    },
    closePopup: () => ({
      popupParams: { ...INITIAL_STATE.popupParams },
    }),
    showThanksPopUp: () => ({
      thanksPopUpShowed: true,
    }),
    setLoadingFromDb: (state, actions, isLoadingFromDb) => ({
      ...state,
      isLoadingFromDb,
    }),
  },
};

export const { Provider, connect, actions } = createStore(config);
