const FORMATS = [
  {
    formatName: 'LinkedIn logo size',
    formatDescription:
      '300 x 300 pixels is the optimal logo dimensions for LinkedIn. Generally, business pages with logo graphics receive six times more visitors than those lacking. The logo even shows up when individuals lookup your company.',
    formatSize: { width: 300, height: 300 },
    selected: false,
    formatColumn: 1,
    id: 'ln_1',
  },
  {
    formatName: 'LinkedIn background photo',
    formatDescription:
      'Recommended LinkedIn background photo specifications: less than 8MB, 1584 x 396 pixels. The background image can work for your benefit, as you may come across as someone who pays attention to details.',
    formatSize: { width: 1584, height: 396 },
    selected: false,
    formatColumn: 1,
    id: 'ln_2',
  },
  {
    formatName: 'LinkedIn link post',
    formatDescription:
      "Optimal images for your LinkedIn account's link post image are essential.In order to respect the LinkedIn image sizes, the recommended size for a shared link post image is 1200 x 627 pixels.",
    formatSize: { width: 1200, height: 627 },
    selected: false,
    formatColumn: 1,
    id: 'ln_3',
  },
  {
    formatName: 'LinkedIn stories',
    formatDescription:
      "The sizes for LinkedIn stories is equivalent to Instagram stories at 1080 x 1920 pixels. If you don't desire to do a live video story, producing your story in images is the ideal thing to do.",
    formatSize: { width: 1080, height: 1920 },
    selected: false,
    formatColumn: 1,
    id: 'ln_4',
  },
  {
    formatName: 'LinkedIn life tab image',
    formatDescription:
      "This tab demands a hero image with the encouraged size being 1128 x 376 pixels. LinkedIn makes it possible for guests to your organization's webpage to toggle between the Overview Tab and Life Tab.",
    formatSize: { width: 1128, height: 376 },
    selected: false,
    formatColumn: 2,
    id: 'ln_5',
  },
  {
    formatName: 'LinkedIn profile picture',
    formatDescription:
      'The perfect size for your personal LinkedIn pfp is 400 x 400 pixels. Any larger LinkedIn profile picture size is fine, as long as it follows the 1:1 ratio. However, you should not choose images smaller than 400 x 400 pixels.',
    formatSize: { width: 400, height: 400 },
    selected: false,
    formatColumn: 2,
    id: 'ln_6',
  },
  {
    formatName: 'LinkedIn cover photo',
    formatDescription:
      'Image Specifications for Your LinkedIn Cover photo are ideal at 1128 x 191 pixels. The banner image, or cover photo is positioned beneath the horizontal logo within your primary company page.',
    formatSize: { width: 1128, height: 191 },
    selected: false,
    formatColumn: 2,
    id: 'ln_7',
  },
  {
    formatName: 'LinkedIn blog post link',
    formatDescription:
      "To keep the content simple to have a look at, you may need to apply an image that's 1200 x 627 pixels. This is the advised LinkedIn image size for sharing a link in an update.",
    formatSize: { width: 1200, height: 627 },
    selected: false,
    formatColumn: 2,
    id: 'ln_8',
  },
  {
    formatName: 'LinkedIn square post',
    formatDescription:
      "A square post - whether it's an image or a video  needs to be sized at 1200 x 1200 pixels. To get the perfect square post, you'll want image dimensions to be on point.",
    formatSize: { width: 1200, height: 1200 },
    selected: false,
    formatColumn: 2,
    id: 'ln_9',
  },
  {
    formatName: 'LinkedIn dynamic ad',
    formatDescription:
      'Spotlight ads are a Dynamic Ad format that are customized to every LinkedIn member. Ad description inserted above image: Use up to 70 characters, including spaces. Company image: Minimum image size: 100 x 100 pixels for JPG or PNG.',
    formatSize: { width: 100, height: 100 },
    selected: false,
    formatColumn: 3,
    id: 'ln_10',
  },
  {
    formatName: 'LinkedIn sponsored content',
    formatDescription:
      'A graphic size of 1200 x 627 pixels is advisable. Content with dominant visuals are likely to receive up to 38% better CTR. Include a straightforward CTA.so your audience knows how to act on their interest.',
    formatSize: { width: 1200, height: 627 },
    selected: false,
    formatColumn: 3,
    id: 'ln_11',
  },
  {
    formatName: 'LinkedIn business banner',
    formatDescription:
      'The dimensions of a LinkedIn Business Hero Image is 646 x 220 pixels at minimum. Allow your business banner to catch the eyes of your prospects! You can make it appealing with a great LinkedIn Business Hero Image.',
    formatSize: { width: 646, height: 220 },
    selected: false,
    formatColumn: 3,
    id: 'ln_12',
  },
  {
    formatName: 'LinkedIn portrait post',
    formatDescription:
      "For portrait posts, we reccomend 1080 x 1350 pixels, these will appear onsite as a 600 x 749 pixels image. It's important to pay attention to the details in order to manage visuals professionally.",
    formatSize: { width: 1080, height: 1350 },
    selected: false,
    formatColumn: 3,
    id: 'ln_13',
  },
];

export default FORMATS;
