const FORMATS = [
  {
    formatName: 'YouTube profile picture',
    formatDescription:
      'A YouTube pfp should be a minimum of 250 x 250 pixels at minimum. Bigger YouTube profile picture sizes will instantly be reduced. The ideal YouTube profile picture  or channel icon size is 800 x 800 pixels. Our pfp maker uses the latter for your YouTube pfp.',
    formatSize: { width: 800, height: 800 },
    selected: false,
    formatColumn: 1,
    id: 'yt_b_1',
  },
  {
    formatName: 'YouTube display ads',
    formatDescription:
      'Display ads show up to the right of a video. Ad dimensions are 300 x 250 pixels or 300 x 60 pixels. Display ads on YouTube are exclusively offered for desktop marketing.',
    formatSize: { width: 300, height: 250 },
    selected: false,
    formatColumn: 1,
    id: 'yt_b_2',
  },
  {
    formatName: 'YouTube banner',
    formatDescription:
      'Your YouTube banner background will show in various dimensions across different platforms. The recommended YouTube channel banner size is 2560 x 1440 pixels for all devices. Then, view your image on different devices and make adjustments based on platform.',
    formatSize: { width: 2560, height: 1440 },
    selected: false,
    formatColumn: 2,
    id: 'yt_b_3',
  },
  {
    formatName: 'YouTube companion banner ads',
    formatDescription:
      'Companion banner ads specs: 300 x 60 pixels. A clickable thumbnail on a YouTube page that is found alongside the ad. A companion banner gives you ongoing brand presence once a video stops, and the audience can click on it at any time.',
    formatSize: { width: 300, height: 60 },
    selected: false,
    formatColumn: 2,
    id: 'yt_b_4',
  },
  {
    formatName: 'YouTube thumbnail size',
    formatDescription:
      "Enhance your own YouTube thumbnails by using the correct thumbnail size: 1280 x 720 pixels, with a bare minimum width of 640 pixels. A ratio of 16:9 is best as it's implemented most often in YouTube players and previews.",
    formatSize: { width: 1280, height: 720 },
    selected: false,
    formatColumn: 3,
    id: 'yt_b_5',
  },
  {
    formatName: 'YouTube overlay ads',
    formatDescription:
      "Overlay ads should match the following specs: 480 × 60 pixels. Overlay ads are compact static pics that appear as an overlay towards the bottom of a clip. Clicking these ads will open the advertiser's landing page.",
    formatSize: { width: 480, height: 60 },
    selected: false,
    formatColumn: 3,
    id: 'yt_b_6',
  },
];

export default FORMATS;
