const FORMATS = [
  {
    formatName: 'Email blog image',
    formatDescription:
      'Wonderful images should help your blog posts stick out. The best image size for blog images is 750 x 750 pixels. If you do this, your visuals will continue to work well whenever your posts are shared on many social platforms.',
    formatSize: { width: 750, height: 750 },
    selected: false,
    formatColumn: 1,
    id: 'em_1',
  },
  {
    formatName: 'Email blog featured',
    formatDescription:
      "Our recommendation for blog featured email images is 1200 x 600 pixels. Blog featured images essentially rely on the kind of blog listing template you're working with. In fact, they are a clear and concise strategy for spreading information.",
    formatSize: { width: 1200, height: 600 },
    selected: false,
    formatColumn: 2,
    id: 'em_2',
  },
  {
    formatName: 'Email header image',
    formatDescription:
      "The best header image specs for email is where it counts. More often than not, it's looked over without a second thought. Header graphics should be between 600 pixels in width, with a proportional height of 200 pixels.",
    formatSize: { width: 600, height: 200 },
    selected: false,
    formatColumn: 3,
    id: 'em_3',
  },
];

export default FORMATS;
