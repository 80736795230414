const FORMATS = [
  {
    formatName: 'Pinterest portrait carousel',
    formatDescription:
      'Simply remember two options: 1000 x 1500 pixels (2:3), or 1000 x 1000 pixels (1:1). A Pinterest portrait carousel of several images is an interactive way to engage with your audience and connect with them emotionally.',
    formatSize: { width: 1000, height: 1500 },
    selected: false,
    formatColumn: 1,
    id: 'pt_1',
  },
  {
    formatName: 'Pinterest story pins',
    formatDescription:
      'You should stick to a size of 1080 x 1920 pixels, and your visuals can become much more dynamic. Share multiple images and words through one Pinterest story pin with up to 20 images, videos and text.',
    formatSize: { width: 1080, height: 1920 },
    selected: false,
    formatColumn: 1,
    id: 'pt_2',
  },
  {
    formatName: 'Pinterest pfp',
    formatDescription:
      'When using the pfp maker, choose a logo or image that showcases your product. The ideal Pinterest pfp will be 165 x 165 pixels in size. Get the right Pinterest profile photo that represents you so that what you stand for is immediately recognizable.',
    formatSize: { width: 165, height: 165 },
    selected: false,
    formatColumn: 2,
    id: 'pt_3',
  },
  {
    formatName: 'Pinterest vertical pin',
    formatDescription:
      'Remember that they’re displayed vertically and that your ideal size is 1000 x 1500 pixels. Build engagement through increased exposure by creating relevant and appealing content for your pins. Keywords and catchy images are great ways to do this.',
    formatSize: { width: 1000, height: 1500 },
    selected: false,
    formatColumn: 2,
    id: 'pt_4',
  },
  {
    formatName: 'Pinterest square carousel',
    formatDescription:
      'The ideal size is 1000 x 1000 pixels, and make sure that you design something specifically attractive for your target audience. A carousel pin is a powerful and fun way to showcase different aspects of your product or service.',
    formatSize: { width: 1000, height: 1000 },
    selected: false,
    formatColumn: 3,
    id: 'pt_5',
  },
  {
    formatName: 'Pinterest board display',
    formatDescription:
      'Keep everything to 222 x 150 pixels and don’t forget that you can add copy to images to provide context. Make great Pinterest board displays with exciting pins that also show your logo.',
    formatSize: { width: 222, height: 150 },
    selected: false,
    formatColumn: 3,
    id: 'pt_6',
  },
];

export default FORMATS;
